









import { Component, Vue, Watch } from "vue-property-decorator"
import Main from "@/Main.vue"
import Foot from "@/Foot.vue"

@Component({
  components: {Foot, Main}
})
export default class App extends Vue {
  get size() { return this.$vuetify.breakpoint.name }
  @Watch("size", { immediate: true }) onSize(size: string) { console.log(">>", size) }
}
