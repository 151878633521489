







import {Vue, Component, Prop} from "vue-property-decorator"
import Bmac from "@/comps/Bmac.vue"

@Component({
  components: {Bmac}
})
export default class Foot extends Vue {
}
