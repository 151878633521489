
















import {Vue, Component, Prop} from "vue-property-decorator"

@Component({
  components: {}
})
export default class Story extends Vue {
}
