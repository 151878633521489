






















import {Vue, Component, Prop} from "vue-property-decorator"
import Story from "@/comps/Story.vue"
import Info from "@/comps/Info.vue"
import FunFact from "@/comps/FunFact.vue"
import FbBtn from "@/comps/FbBtn.vue"

@Component({
  components: {FbBtn, FunFact, Info, Story}
})
export default class Home extends Vue {
  //get size() { return this.$vuetify.breakpoint.name }
  get titleClass() {
    return this.$vuetify.breakpoint.smAndDown ? "title-sm" : "title-lg"
  }
}
